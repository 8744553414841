/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/
/* line 6, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.clearfix {
  *zoom: 1; }

/* line 7, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.clearfix:before, .clearfix:after {
  display: table;
  line-height: 0;
  content: ""; }

/* line 8, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.clearfix:after {
  clear: both; }

/* line 9, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.clear {
  clear: both; }

/* line 11, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.oh {
  overflow: hidden; }

/* line 12, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.relative {
  position: relative; }

/* line 13, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.section-wrap, .section-wrap-mp {
  padding: 150px 0;
  overflow: hidden;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

/* line 14, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.section-wrap-mp {
  padding: 100px 0; }

/* line 15, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.color-white {
  color: #fff !important; }

/* line 16, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.left {
  float: left; }

/* line 17, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.right {
  float: right; }

/* line 18, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.bg-light {
  background-color: #f7f7f7; }

/* line 19, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.bg-dark {
  background-color: #242424; }

/* line 20, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.last {
  margin-bottom: 0 !important; }

/* line 22, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
::-moz-selection {
  color: #fff;
  background: #000; }

/* line 23, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
::-webkit-selection {
  color: #fff;
  background: #000; }

/* line 24, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
::selection {
  color: #fff;
  background: #000; }

/* line 26, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
a {
  text-decoration: none;
  color: #2b8040;
  outline: none;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out; }
  /* line 32, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
  a:hover, a:focus {
    text-decoration: none;
    color: #7a7a7a;
    outline: none; }

/* line 40, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
:focus {
  outline: none; }

/* line 44, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

/* line 50, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: #7a7a7a;
  font-size: 15px;
  line-height: 1.5;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto; }

/* line 65, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
video {
  height: 100%;
  width: 100%; }

/* line 70, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
body img {
  border: none;
  max-width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

/* line 80, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  margin-top: 0;
  text-transform: uppercase;
  color: #111;
  letter-spacing: 0.05em;
  font-weight: 700; }

/* line 89, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h1 {
  font-size: 28px; }

/* line 90, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h2 {
  font-size: 24px; }

/* line 91, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h3 {
  font-size: 21px; }

/* line 92, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h4 {
  font-size: 20px; }

/* line 93, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h5 {
  font-size: 18px; }

/* line 94, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
h6 {
  font-size: 15px; }

/* line 96, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
p {
  font-size: 15px;
  color: #7a7a7a;
  font-weight: normal;
  line-height: 25px; }

/* line 103, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.subheading {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: italic; }

/* line 109, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.heading {
  margin-bottom: 60px; }

/* line 113, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.bottom-line:after {
  content: "";
  display: block;
  width: 48px;
  border-bottom: 3px solid #e8e8e8;
  margin: 15px auto 15px; }

/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
/* line 126, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999; }

/* line 136, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 5px;
  width: 5em;
  height: 5em;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  border-top: 0.5em solid #2b8040;
  border-right: 0.5em solid rgba(245, 245, 245, 0.5);
  border-bottom: 0.5em solid rgba(245, 245, 245, 0.5);
  border-left: 0.5em solid rgba(245, 245, 245, 0.5);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

/* line 156, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_general.scss */
.loader,
.loader:after {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 10em;
  height: 10em; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (max-width: 991px) {
  /* line 3, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-header {
    float: none; }
  /* line 6, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-left, .navbar-nav, .navbar-right {
    float: none !important; }
  /* line 9, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-toggle {
    display: block; }
  /* line 12, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  /* line 16, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px; }
  /* line 20, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-collapse.collapse {
    display: none !important; }
  /* line 23, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav {
    margin-top: 7.5px; }
  /* line 26, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav > li {
    float: none; }
  /* line 29, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  /* line 33, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .collapse.in {
    display: block !important; }
  /* line 36, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; } }

/*-------------------------------------------------------*/
/* Navigation Onepage
/*-------------------------------------------------------*/
/* line 52, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-1 .container-fluid {
  padding: 0 50px; }

/* line 56, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar {
  margin-bottom: 0;
  border: none;
  min-height: 60px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1200; }

/* line 66, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-nav {
  margin: 0;
  float: none;
  display: inline-block; }
  /* line 71, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav > li > a {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    padding: 0 20px;
    line-height: 110px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.02em;
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

/* line 84, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  line-height: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  /* line 90, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navigation-overlay.sticky {
    background-color: white;
    visibility: visible;
    opacity: 1; }
  /* line 96, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navigation-overlay.sticky .navbar-nav > li > a,
  .navigation-overlay.sticky .menu-socials li > a {
    line-height: 60px; }

/* line 102, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-header {
  width: 20%;
  padding-right: 15px; }

/* line 107, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-wrap {
  width: 60%; }

/* line 111, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.menu-socials {
  width: 20%;
  float: right;
  padding: 0 15px; }
  /* line 116, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .menu-socials ul {
    float: right; }
  /* line 120, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .menu-socials li {
    display: inline-block; }
  /* line 124, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .menu-socials li > a {
    font-size: 16px;
    line-height: 90px;
    margin-left: 22px;
    color: #fff;
    border-bottom: 1px solid #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  /* line 133, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .menu-socials li > a:hover {
    border-color: #2b8040; }

/* line 138, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.logo-container {
  padding: 0 15px;
  float: left; }

/* line 143, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.logo-wrap {
  display: table;
  width: 100%; }
  /* line 147, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .logo-wrap > a {
    display: table-cell;
    vertical-align: middle;
    height: 110px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  /* line 154, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .logo-wrap.shrink > a {
    height: 60px; }
  /* line 158, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .logo-wrap img {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    height: 86px; }
  /* line 163, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .logo-wrap.shrink img {
    height: 48px; }

/* line 168, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-collapse.in {
  overflow-x: hidden; }

/* line 172, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-nav > li > a:hover,
.navbar-nav > .active > a,
.navbar-nav > .active > a:focus,
.navbar-nav > .active > a:hover,
.menu-socials li > a:hover {
  opacity: 1;
  color: #2b8040 !important; }

/* line 181, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-collapse {
  padding: 0;
  border-top: none; }

/* line 186, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent;
  text-decoration: none;
  border-color: #f2f2f2; }

@media (max-width: 991px) {
  /* line 198, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navigation-overlay,
  .navigation-overlay.sticky {
    background-color: rgba(17, 17, 17, 0.9); }
  /* line 203, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-wrap {
    width: 100%;
    padding: 0;
    min-height: 0; }
  /* line 209, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-1 .container-fluid {
    padding: 0 15px; }
  /* line 213, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-header {
    width: 100%;
    padding-right: 0; }
  /* line 218, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .logo-wrap img {
    height: 48px; }
  /* line 222, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .logo-wrap > a {
    height: 60px; }
  /* line 226, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav {
    width: 100%;
    padding: 40px 0; }
  /* line 231, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navigation-overlay.sticky .navbar-nav > li > a,
  .navigation-overlay .navbar-nav > li > a {
    padding: 10px 0;
    line-height: 30px; } }

/* Navigation Toggle
-------------------------------------------------------*/
/* line 242, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-toggle {
  margin-top: 13px;
  border: none;
  z-index: 50;
  margin-right: 5px; }
  /* line 248, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-toggle .icon-bar {
    background-color: #fff;
    width: 18px; }
  /* line 253, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-toggle:focus, .navbar-toggle:hover {
    background-color: transparent; }
  /* line 258, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-toggle:focus .icon-bar,
  .navbar-toggle:hover .icon-bar {
    background-color: #f2f2f2; }

/*Go to Top*/
/* line 267, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
#back-to-top {
  display: block;
  z-index: 500;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 22px;
  position: fixed;
  bottom: -40px;
  right: 20px;
  line-height: 35px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  background-color: #fff;
  text-decoration: none;
  box-shadow: 1px 1.732px 12px 0px rgba(0, 0, 0, 0.14), 1px 1.732px 3px 0px rgba(0, 0, 0, 0.12); }
  /* line 284, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #back-to-top i {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out; }
  /* line 288, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #back-to-top a {
    width: 40px;
    height: 40px;
    display: block;
    color: #111; }
  /* line 295, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #back-to-top.show {
    bottom: 20px; }
  /* line 299, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #back-to-top:hover {
    background-color: #111; }
  /* line 303, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #back-to-top:hover i {
    color: #fff; }

/*-------------------------------------------------------*/
/* Navigation Multi-Page
/*-------------------------------------------------------*/
/* line 314, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-2 .navbar {
  min-height: 90px; }

/* line 318, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-2 .nav-wrap {
  width: 80%; }

/* line 322, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-2 .navbar-nav > li > a {
  padding: 0 15px; }

/* line 326, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-2 .navbar-toggle:focus .icon-bar,
.nav-type-2 .navbar-toggle:hover .icon-bar {
  background-color: #111; }

/* line 331, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-2 .navbar-nav > li > a {
  color: #111;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out; }

/* line 337, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-2 .navbar-toggle .icon-bar,
.nav-type-4 .navbar-toggle .icon-bar {
  background-color: #7a7a7a; }

/* line 343, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation.offset {
  -webkit-transform: translate3d(0, -300px, 0);
  -moz-transform: translate3d(0, -300px, 0);
  -ms-transform: translate3d(0, -300px, 0);
  -o-transform: translate3d(0, -300px, 0);
  transform: translate3d(0, -300px, 0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 348, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation.sticky {
  position: fixed;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 60px;
  top: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1009; }

/* line 360, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation.scrolling {
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0); }

/* line 364, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation.sticky.scrolling {
  opacity: 1;
  visibility: visible; }

/* line 369, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation.sticky .navbar-nav > li > a {
  line-height: 60px; }

/* line 374, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-nav .dropdown-menu {
  left: 0; }
  /* line 377, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .navbar-nav .dropdown-menu.menu-right {
    left: auto;
    right: 0; }

/* line 383, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.dropdown-menu {
  min-width: 200px;
  margin: 0;
  padding: 10px 0;
  border-top: 3px solid #2b8040;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); }
  /* line 394, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .dropdown-menu > li > a {
    padding: 10px 20px;
    font-size: 13px;
    color: #7a7a7a;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 500; }
  /* line 402, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {
    background-color: transparent;
    color: #2b8040; }
  /* line 408, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .dropdown-menu .megamenu-wrap ul > li > a {
    display: block;
    clear: both;
    font-weight: 500;
    line-height: 1.42857143;
    color: #7a7a7a;
    white-space: nowrap;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

/* line 419, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.dropdown-submenu > a:after {
  font-family: "FontAwesome";
  position: absolute;
  content: "\f105";
  right: 15px;
  color: #7a7a7a; }

/* line 427, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.dropdown-menu,
.dropdown-submenu > .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

/* line 435, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.dropdown:hover > .dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
  opacity: 1;
  visibility: visible; }

/* line 441, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar .dropdown-menu {
  margin-top: 0; }

/* line 445, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.dropdown-submenu {
  position: relative; }
  /* line 448, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -2px; }

/* Menu Search
-------------------------------------------------------*/
/* line 457, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navbar-nav > li > a.nav-search {
  position: relative;
  display: block;
  margin: 38px 0 37px;
  width: 15px;
  height: 15px;
  padding-left: 10px; }

/* line 466, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.navigation.sticky .navbar-nav > li > a.nav-search {
  margin: 23px 0 22px; }

/* line 470, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20; }
  /* line 480, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .search-wrap .form-control {
    height: 100%;
    border: none;
    font-size: 24px; }
  /* line 486, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .search-wrap input,
  .search-wrap input:focus {
    background-color: #fff !important;
    border: none; }

/* line 493, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.search-trigger {
  z-index: 22;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
  opacity: 1; }

/* line 502, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.search-close {
  opacity: 0;
  font-size: 16px;
  z-index: 21;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 0; }

/* line 512, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-search.open .search-trigger {
  opacity: 0;
  z-index: 20; }

/* line 517, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-search.open .search-close {
  opacity: 1; }

/* line 521, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
#mobile-search {
  display: none; }

/*-------------------------------------------------------*/
/* Navigation Portfolio
/*-------------------------------------------------------*/
/* line 529, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-3 {
  background-color: #fff;
  opacity: .9;
  position: fixed;
  height: 100px;
  width: 100%;
  z-index: 1000; }
  /* line 537, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-3 .nav-wrap {
    display: table;
    height: 100px;
    width: 100%;
    float: left; }
  /* line 544, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-3 .logo-container a {
    z-index: 11;
    vertical-align: middle;
    display: table-cell;
    height: 100px;
    padding: 0 20px; }

/* line 553, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.full-nav,
#nav-icon {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 563, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.fs-menu {
  position: fixed;
  background-color: rgba(12, 12, 12, 0.95);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  overflow: hidden; }
  /* line 575, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open {
    opacity: .9;
    visibility: visible;
    z-index: 200;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear; }
  /* line 582, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li {
    -webkit-animation: fadeInUp .35s ease forwards;
    -moz-animation: fadeInUp .35s ease forwards;
    -ms-animation: fadeInUp .35s ease forwards;
    -o-animation: fadeInUp .35s ease forwards;
    animation: fadeInUp .35s ease forwards;
    -webkit-animation-delay: .10s;
    -moz-animation-delay: .10s;
    -ms-animation-delay: .10s;
    -o-animation-delay: .10s;
    animation-delay: .10s; }
  /* line 595, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li:nth-of-type(2) {
    animation-delay: .15s; }
  /* line 598, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li:nth-of-type(3) {
    animation-delay: .20s; }
  /* line 601, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li:nth-of-type(4) {
    animation-delay: .25s; }
  /* line 604, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li:nth-of-type(5) {
    animation-delay: .30s; }
  /* line 607, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li:nth-of-type(6) {
    animation-delay: .35s; }
  /* line 610, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .fs-menu.open li:nth-of-type(7) {
    animation-delay: .40s; }

/* line 615, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.overlay-menu {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center; }
  /* line 621, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .overlay-menu ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%; }
  /* line 630, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .overlay-menu ul li {
    display: block;
    position: relative;
    opacity: 0;
    padding: 15px 0; }
  /* line 637, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .overlay-menu ul li a {
    display: block;
    position: relative;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    letter-spacing: 0.05em;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear; }
  /* line 651, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .overlay-menu ul li.active > a,
  .overlay-menu ul li a:hover {
    color: #2b8040; }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    bottom: 20%; }
  100% {
    opacity: 1;
    bottom: 0; } }

/* Nav icon */
/* line 670, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
#nav-icon {
  right: 40px;
  top: 50%;
  margin-top: -7px;
  position: absolute;
  margin-right: 0;
  z-index: 300;
  cursor: pointer;
  width: 50px;
  height: 40px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer; }
  /* line 684, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #111;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -ms-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out; }
  /* line 696, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon span:nth-child(1) {
    top: 0px; }
  /* line 700, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    top: 8px; }
  /* line 705, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon span:nth-child(4) {
    top: 16px; }
  /* line 709, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon.open span:nth-child(1) {
    top: 6px;
    width: 0%;
    left: 50%; }
  /* line 715, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff; }
  /* line 720, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: #fff; }
  /* line 725, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  #nav-icon.open span:nth-child(4) {
    top: 6px;
    width: 0%;
    left: 50%; }

/*-------------------------------------------------------*/
/* Navigation Leftside
/*-------------------------------------------------------*/
/* line 738, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.sidenav .container-fluid,
.sidenav .container {
  width: 90%; }

/* line 743, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.sidenav .result-boxes .container-fluid {
  width: 100%; }

/* line 747, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.sidenav .header-wrap {
  width: 300px;
  height: 100%;
  background-color: #fff;
  padding: 70px 50px; }

/* line 754, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.sidenav .works-grid-3-col-wide .container-fluid {
  padding: 0 15px; }

/* line 760, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.content-wrap {
  margin-left: 300px;
  position: relative;
  width: auto; }

/* line 766, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
.nav-type-4 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  height: 100%;
  width: 300px; }
  /* line 774, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .navbar-header,
  .nav-type-4 .nav-wrap {
    width: 100%; }
  /* line 779, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .navbar-header {
    padding: 0; }
  /* line 783, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .navbar {
    min-height: 100%; }
  /* line 787, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .logo-container {
    width: 100%;
    padding: 0; }
  /* line 792, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .logo-wrap > a {
    height: auto;
    text-align: center; }
  /* line 797, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .nav {
    width: 100%;
    margin-top: 40px; }
  /* line 802, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .navbar-nav > li {
    float: none; }
  /* line 806, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .navbar-nav > li > a {
    padding: 17px 0;
    line-height: 1;
    color: #111; }
  /* line 812, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
  .nav-type-4 .social-icons a {
    float: none !important; }
    /* line 815, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_navigation.scss */
    .nav-type-4 .social-icons a:hover {
      color: #111; }

/*-------------------------------------------------------*/
/* Content Home
/*-------------------------------------------------------*/
/* Intro
-------------------------------------------------------*/
/* line 8, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.intro-heading {
  font-size: 28px;
  margin-bottom: 40px; }

/* line 13, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.heading-frame {
  padding: 38px 50px;
  border: 5px solid #111;
  display: inline-block; }

/* line 19, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.intro-text {
  font-size: 17px;
  line-height: 32px; }

/* Results
-------------------------------------------------------*/
/* line 27, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-box {
  padding: 130px 0; }

/* line 31, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-box-first {
  background-color: #1b1b1b; }

/* line 32, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-box-second {
  background-color: #212121; }

/* line 33, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-box-third {
  background-color: #292929; }

/* line 35, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-wrap {
  width: 230px;
  margin: 0 auto; }
  /* line 39, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .result-wrap i {
    font-size: 55px;
    color: #fff;
    float: left;
    margin-top: 3px; }

/* line 47, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.statistic span {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  display: block;
  line-height: 1;
  margin-bottom: 9px;
  margin-left: 20px;
  float: left; }
  /* line 57, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .statistic span.counter-text {
    font-size: 13px;
    text-transform: uppercase;
    color: #929292;
    margin-bottom: 0; }

/* Services
-------------------------------------------------------*/
/* line 69, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.service-item-box {
  background-color: #fff;
  padding: 60px 40px;
  margin-bottom: 30px; }
  /* line 74, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .service-item-box h3 {
    font-size: 15px;
    margin-bottom: 21px; }
  /* line 79, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .service-item-box > a {
    display: inline-block;
    margin-bottom: 27px; }
  /* line 84, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .service-item-box i {
    display: block;
    font-size: 24px;
    line-height: 70px;
    color: #fff; }

/*Hi Icons*/
/* line 95, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.hi-icon {
  display: inline-block;
  font-size: 0px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff; }
  /* line 107, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .hi-icon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }

/* Effect 1 */
/* line 122, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.icon-effect-1 .hi-icon {
  background-color: transparent;
  color: #2b8040;
  border: 2px solid #2b8040;
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  -ms-transition: background 0.2s, color 0.2s;
  -o-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s; }
  /* line 128, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .icon-effect-1 .hi-icon:after {
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-box-shadow: 0 0 0 2px #111;
    -moz-box-shadow: 0 0 0 2px #111;
    -ms-box-shadow: 0 0 0 2px #111;
    box-shadow: 0 0 0 2px #111;
    -webkit-transition: transform 0.2s, opacity 0.2s;
    -moz-transition: transform 0.2s, opacity 0.2s;
    -ms-transition: transform 0.2s, opacity 0.2s;
    -o-transition: transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
  /* line 138, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .icon-effect-1 .hi-icon:hover {
    background: #111111;
    color: #fff;
    border-color: transparent; }
  /* line 144, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .icon-effect-1 .hi-icon:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1; }

/* Promo Section
-------------------------------------------------------*/
/* line 154, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.promo-section img {
  width: 100%; }

/* line 158, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.promo-description {
  padding: 50px 0 50px 50px;
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }
  /* line 166, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .promo-description > h3 {
    margin-bottom: 22px; }
  /* line 170, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .promo-description > p {
    color: #a6a6a6; }

/* line 175, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.promo-section .customNavigation > a,
.from-blog .customNavigation > a {
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 24px; }
  /* line 182, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .promo-section .customNavigation > a:first-child, .promo-section .customNavigation > a:first-child,
  .from-blog .customNavigation > a:first-child,
  .from-blog .customNavigation > a:first-child {
    margin-right: 10px; }
  /* line 187, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .promo-section .customNavigation > a:hover, .promo-section .customNavigation > a:hover,
  .from-blog .customNavigation > a:hover,
  .from-blog .customNavigation > a:hover {
    opacity: .8; }

/* Portfolio
-------------------------------------------------------*/
/* line 196, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.works-grid-3-col-wide .grayscale img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; }

/* line 205, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item:hover img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none; }

/* line 212, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.works-grid-3-col-wide .container-fluid {
  padding: 0 50px; }

/* line 216, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.grid-3-col.grid-gutter {
  margin: 0 0 -5px -5px; }
  /* line 219, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .grid-3-col.grid-gutter .work-item {
    padding: 0 0 5px 5px;
    width: 33.333%;
    height: auto;
    float: left;
    overflow: hidden; }

/* line 228, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-img {
  position: relative;
  overflow: hidden; }
  /* line 232, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .work-img img {
    overflow: hidden;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  /* line 238, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .work-img a.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: auto;
    width: auto; }

/* line 248, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-img > a,
.work-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: relative; }

/* line 256, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-overlay {
  background-color: rgba(20, 20, 20, 0.7);
  border: 10px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 267, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-description {
  position: absolute;
  display: block;
  left: 8%;
  top: 8%;
  width: 80%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  /* line 275, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .work-description h3 {
    font-size: 16px;
    margin-bottom: 5px;
    color: #fff; }
  /* line 281, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .work-description a {
    color: #fff; }
  /* line 285, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .work-description span {
    font-size: 13px;
    color: #fff;
    display: inline-block; }

/* line 293, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-description h3,
.work-description span {
  -webkit-transform: translateX(-360px);
  -moz-transform: translateX(-360px);
  -ms-transform: translateX(-360px);
  -o-transform: translateX(-360px);
  transform: translateX(-360px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/* line 299, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item:hover img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1);
  -ms-transform: scale3d(1.1, 1.1, 1);
  -o-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1); }

/* line 303, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item:hover .work-description h3,
.work-item:hover .work-description span {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }

/* line 308, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item:hover .work-description span {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s; }

/* line 312, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item:hover .work-overlay {
  opacity: 1;
  z-index: 1; }

/* Call to Action
-------------------------------------------------------*/
/* line 321, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.call-to-action {
  padding: 70px 0;
  background-color: #fff; }
  /* line 325, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .call-to-action h2 {
    font-size: 19px;
    margin-top: 15px; }

/* Process
-------------------------------------------------------*/
/*5 columns*/
/* line 337, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

/* line 347, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.col-xs-5ths {
  width: 20%;
  float: left; }

@media (min-width: 767px) {
  /* line 353, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .col-sm-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  /* line 360, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .col-md-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  /* line 367, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .col-lg-5ths {
    width: 20%;
    float: left; } }

/* line 373, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.process-wrap i {
  font-size: 32px;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff; }

/* line 385, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.process-wrap h3 {
  color: #fff;
  font-size: 13px;
  margin: 30px 0 20px; }

/* Our Team
-------------------------------------------------------*/
/* line 395, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.our-team .team-row {
  margin-left: -40px;
  margin-right: -40px; }

/* line 400, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.our-team .team-wrap {
  padding: 0 40px; }

/* line 404, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.our-team .container-fluid {
  padding: 0 50px; }

/* line 408, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.team-img img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  width: 100%; }

/* line 414, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.team-img:hover .team-details {
  opacity: 1;
  margin-top: -80px; }

/* line 419, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.team-img:hover .overlay {
  opacity: 1; }

/* line 423, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.team-member,
.team-img {
  position: relative;
  overflow: hidden; }

/* line 429, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.team-title {
  margin: 30px 0 7px; }

/* line 433, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.overlay {
  background-color: rgba(20, 20, 20, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/* line 443, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.team-details {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  /* line 454, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .team-details p {
    color: #fff; }
  /* line 458, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .team-details .socials i {
    color: #fff; }

/* line 466, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.socials a {
  display: inline-block;
  width: 37px;
  height: 37px;
  background-color: transparent; }
  /* line 472, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .socials a:hover i {
    color: #fff;
    background-color: #2b8040; }

/* line 478, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.socials i {
  line-height: 37px;
  color: #616161;
  font-size: 14px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }

/* About us
-------------------------------------------------------*/
/* line 495, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.about-description h4,
.about-description p:first-of-type {
  margin-bottom: 15px; }

/* Progress Bars
-------------------------------------------------------*/
/* line 504, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#skills {
  margin-top: 35px; }

/* line 508, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.progress-bar {
  -webkit-transition: width 1.5s ease-in-out;
  -moz-transition: width 1.5s ease-in-out;
  -ms-transition: width 1.5s ease-in-out;
  -o-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none; }

/* line 513, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.skills-progress h6,
.skills-progress h6 span {
  color: #111;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.02em; }

/* line 524, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.progress-solid.meter {
  height: 8px;
  position: relative;
  background: #fbfbfb;
  margin-bottom: 30px; }

/* line 531, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.meter .progress-bar {
  display: block;
  height: 8px;
  position: relative;
  overflow: hidden;
  background-color: #2b8040; }

/* line 539, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.skills-progress h6 span {
  float: right; }

/* line 543, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.row.mt-minus-30 {
  margin-top: -30px; }

/* From Blog
-------------------------------------------------------*/
/* line 551, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-col {
  padding: 0 5px; }
  /* line 554, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .blog-col h4 {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 4px;
    line-height: 1.5; }
  /* line 561, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .blog-col h4 a {
    color: #111; }

/* line 566, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.from-blog .customNavigation > a {
  color: #111; }

/* line 570, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-box {
  padding: 40px;
  background-color: #fff; }

/* line 577, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-meta li {
  display: inline-block;
  font-size: 13px;
  color: #919191;
  margin-bottom: 15px;
  white-space: nowrap; }

/* line 585, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-meta a {
  color: #919191; }

/* line 589, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-meta li:before {
  content: "|";
  opacity: 0.5;
  margin: 0 7px; }

/* line 595, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-meta li:first-child:before {
  content: "";
  margin: 0; }

/* line 602, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-meta a:hover,
.blog-col h4 a:hover {
  color: #111; }

/* line 607, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-img {
  position: relative;
  overflow: hidden; }
  /* line 611, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .entry-img img {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    width: 100%; }

/* line 617, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.read-more {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: bold;
  color: #111; }
  /* line 625, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .read-more:hover {
    color: #111; }

/* line 630, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-col-3 .entry-img:hover img {
  opacity: 0.8;
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1); }

/* line 636, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flex-direction-nav a {
  display: block;
  font-size: 16px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  text-align: center;
  color: #fff;
  line-height: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 655, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flex-direction-nav .flex-prev {
  left: -40px; }

/* line 659, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flex-direction-nav .flex-next {
  right: -40px; }

/* line 664, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flexslider:hover .flex-direction-nav a {
  opacity: 1; }

/* line 668, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flexslider:hover .flex-direction-nav .flex-next {
  right: 0; }

/* line 672, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flexslider:hover .flex-direction-nav .flex-prev {
  left: 0; }

/* line 676, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.flexslider .flex-direction-nav .flex-nav-next:hover a,
.flexslider .flex-direction-nav .flex-nav-prev:hover a {
  color: #000;
  background-color: #fff; }

/* Testimonials
-------------------------------------------------------*/
/* line 687, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonial-text {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

/* line 695, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.parallax-testimonials .bottom-line:after {
  border-color: #fff; }

/* line 699, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonial-text {
  font-size: 24px;
  line-height: 39px;
  color: #fff;
  font-style: italic;
  margin: 30px 0;
  font-family: "Montserrat", sans-serif; }

/* line 709, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonial i {
  font-size: 28px;
  color: #fff; }

/* line 714, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonial span,
.testimonial h6 {
  display: block;
  color: #fff; }

/* line 720, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonial span {
  font-size: 13px; }

/* line 724, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonial h6 {
  font-size: 13px;
  margin-bottom: 5px; }

/* line 730, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.parallax-testimonials .owl-pagination {
  position: relative;
  margin-top: 50px; }

/* line 735, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.parallax-testimonials .owl-carousel {
  position: static; }

/* Clients
-------------------------------------------------------*/
/* line 743, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.client-logo {
  border-bottom: 1px solid #dedede;
  border-right: 1px solid #dedede;
  text-align: center; }
  /* line 748, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .client-logo:last-child {
    border-right: none; }

/* line 753, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.second-row .client-logo {
  border-bottom: none; }

/* Contact
-------------------------------------------------------*/
/* line 761, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.contact-row {
  margin-left: -8px;
  margin-right: -8px; }

/* line 766, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.contact-name,
.contact-email {
  padding: 0 8px; }

/* line 771, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.contact-item {
  position: relative;
  padding-left: 70px;
  padding-top: 5px;
  margin-top: 30px; }
  /* line 777, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .contact-item:first-of-type {
    margin-top: 38px; }
  /* line 781, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .contact-item h6 {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 7px; }
  /* line 787, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .contact-item p,
  .contact-item span,
  .contact-item a {
    font-size: 16px; }
  /* line 793, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .contact-item a:hover {
    color: #111; }

/* line 798, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.contact-icon {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #2b8040;
  text-align: center;
  position: absolute;
  left: 0; }
  /* line 807, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .contact-icon i {
    font-size: 18px;
    color: #2b8040;
    line-height: 47px; }

/* line 814, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.gmap {
  position: relative;
  width: 100%;
  height: 450px; }

/* line 820, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#contact-form .message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
  color: #fff; }

/* line 832, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#contact-form .message.error {
  background-color: #f44336; }

/* line 833, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#contact-form .message.success {
  background-color: #4CAF50; }

/* Owl Carousel
-------------------------------------------------------*/
/* line 839, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-pagination {
  position: absolute;
  left: 0;
  display: block;
  text-align: center;
  width: 100%; }

/* line 847, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-buttons {
  position: static; }

/* line 851, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-prev, .owl-next {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -23px;
  text-align: center;
  line-height: 46px;
  z-index: 10;
  width: 46px;
  height: 46px;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  /* line 865, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .owl-prev:hover i, .owl-next:hover i {
    color: #2b8040; }

/* line 870, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-prev {
  left: -40px; }

/* line 874, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-next {
  right: -40px; }

/* line 878, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#owl-slider-one-img:hover .owl-prev,
#owl-slider-small-img:hover .owl-prev {
  opacity: 1;
  left: 0; }

/* line 884, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#owl-slider-one-img:hover .owl-next,
#owl-slider-small-img:hover .owl-next {
  opacity: 1;
  right: 0; }

/* line 890, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-page {
  display: inline-block;
  padding: 6px 6px;
  position: relative; }
  /* line 895, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .owl-page span {
    display: block;
    position: relative;
    width: 10px;
    height: 10px;
    opacity: 0.8;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    background: transparent;
    z-index: 100;
    border: 2px solid #fff;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  /* line 908, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .owl-page span:hover {
    opacity: 1; }

/* line 913, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.owl-theme .owl-controls .owl-page.active span {
  display: block;
  width: 10px;
  height: 10px;
  margin: 0;
  opacity: 1;
  background: #fff; }

/*-------------------------------------------------------*/
/* Page Title / Breadcrumbs
/*-------------------------------------------------------*/
/* line 927, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.page-title {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-position: 50% 0;
  display: block; }
  /* line 936, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title .container {
    height: 600px; }
  /* line 940, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title .heading-frame {
    border-color: #fff; }

/* line 945, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.title-holder {
  position: relative;
  display: table;
  width: 100%;
  height: 100%; }

/* line 952, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.title-text {
  display: table-cell;
  height: 100%;
  vertical-align: middle; }
  /* line 957, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .title-text h1 {
    margin: 30px 0;
    font-size: 32px; }

/* line 963, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.page-title .breadcrumb {
  width: auto;
  background-color: transparent;
  padding: 0;
  margin: -10px 0 0 0;
  font-size: 13px; }
  /* line 970, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title .breadcrumb a,
  .page-title .breadcrumb > .active {
    color: #fff; }
  /* line 975, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title .breadcrumb > li + li:before {
    color: #fff;
    opacity: 0.5; }

/*-------------------------------------------------------*/
/* Blog Standard
/*-------------------------------------------------------*/
/* line 988, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-img img {
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative; }

/* line 995, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-img a:hover img {
  opacity: .8; }

/* line 999, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-img,
.blog-content .entry-slider,
.blog-content .entry-video {
  position: relative;
  overflow: hidden;
  margin-bottom: 34px; }

/* line 1007, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-meta li:first-child:before {
  margin: 0 0 0 -3px; }

/* line 1011, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-title h2 > a {
  font-size: 21px;
  color: #111; }

/* line 1016, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-meta li {
  margin-bottom: 7px; }

/* line 1020, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-content {
  margin-top: 17px; }

/* line 1025, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .sidebar,
.blog-single .sidebar {
  padding-left: 65px; }

/* line 1030, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-item {
  margin-bottom: 60px;
  position: relative;
  overflow: hidden; }

/* line 1038, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .entry {
  margin-top: 35px; }

/* line 1042, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .entry-title,
.blog-standard .entry-meta {
  padding-left: 93px; }

/* line 1047, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .entry-item > .entry-date {
  float: left;
  padding: 13px 19px;
  width: 70px;
  height: 70px;
  border: 3px solid #111;
  font-family: "Montserrat", sans-serif;
  color: #3b3b3b;
  font-weight: bold;
  text-align: center; }

/* line 1059, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .blog-content .entry-date span {
  display: block; }

/* line 1063, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .blog-content .entry-date span:first-child {
  font-size: 21px;
  line-height: 1; }

/* line 1068, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-standard .blog-content .entry-date span:last-child {
  font-size: 13px;
  text-transform: uppercase; }

/* line 1074, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-content > i {
  margin-left: 3px;
  font-size: 14px;
  vertical-align: middle; }

/* Quote Post
-------------------------------------------------------*/
/* line 1084, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry.blockquote {
  padding-left: 0; }

/* line 1088, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-item blockquote > p > a,
.blog-content .entry-item blockquote > p {
  color: #111;
  font-size: 22px;
  line-height: 34px;
  font-style: italic;
  font-family: "Montserrat", sans-serif; }

/* line 1097, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-content .entry-item blockquote > p > a:hover {
  color: #7a7a7a; }

/* line 1101, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blockquote-style-1 > i {
  font-size: 22px;
  color: #d1d1d1;
  margin-bottom: 13px; }

/* Video Post
-------------------------------------------------------*/
/* line 1111, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-video iframe {
  width: 100%;
  display: block;
  border: 0; }

/* Pagination
-------------------------------------------------------*/
/* line 1121, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pagination {
  margin: 0 auto;
  border-radius: 0;
  display: block;
  text-align: center;
  padding-top: 42px;
  border-top: 1px solid #e5e5e5; }
  /* line 1129, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination a,
  .pagination span {
    background-color: #fff;
    font-size: 12px;
    display: inline-block;
    height: 25px;
    line-height: 20px;
    text-align: center;
    margin: 0 9px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    text-transform: uppercase; }
  /* line 1144, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination span.pagination-dots {
    margin: 0 3px; }
  /* line 1148, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination i:first-child {
    margin-right: -5px; }
  /* line 1152, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination i:last-child {
    margin-left: -7px; }
  /* line 1156, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination a {
    color: #757575; }
  /* line 1160, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination a:hover {
    color: #111; }
  /* line 1164, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination a > i {
    font-size: 10px;
    margin: 0 3px; }
  /* line 1169, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination .current {
    color: #3b3b3b; }
  /* line 1173, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pagination > i {
    font-size: 14px;
    vertical-align: middle; }

/*-------------------------------------------------------*/
/* Blog Sidebar
/*-------------------------------------------------------*/
/* line 1186, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget {
  margin-top: 60px;
  position: relative; }

/* line 1191, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget:first-child {
  margin-top: 0; }

/* line 1195, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget ul li {
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5; }

/* line 1200, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget ul li:last-child {
  padding-bottom: 0;
  border-bottom: none; }

/* line 1205, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget ul li:first-child {
  padding-top: 0; }

/* line 1209, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget ul li a {
  color: #7a7a7a; }

/* line 1213, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.sidebar .widget ul li a:hover {
  color: #111; }

/* line 1219, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.widget-title {
  font-size: 15px;
  margin-bottom: 28px; }

/* line 1224, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-sidebar .searchbox {
  border: 2px solid #e5e5e5;
  margin-bottom: 0;
  padding: 0 20px; }

/* line 1230, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
input[type="search"] {
  color: #111; }

/* line 1234, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
input.searchbox:focus {
  border-color: #2b8040;
  color: #111; }

/* line 1239, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.search-button {
  position: absolute;
  top: 12px;
  right: 15px;
  background-color: transparent;
  border: none; }
  /* line 1246, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .search-button i {
    color: #a1a1a1; }

/* Latest Posts
-------------------------------------------------------*/
/* line 1257, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-sidebar .widget.latest-posts ul li {
  border-bottom: none;
  line-height: 19px; }

/* line 1262, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-sidebar .latest-posts img {
  margin-right: 24px;
  float: left; }

/* line 1267, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-sidebar .latest-posts li {
  padding: 17px 0 !important; }

/* line 1271, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-sidebar .latest-posts li:first-child {
  padding-top: 0 !important; }

/* line 1275, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-sidebar .latest-posts li:last-child {
  padding-bottom: 0 !important; }

/* line 1282, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.widget.latest-posts ul li a {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #111;
  vertical-align: top;
  line-height: 1; }

/* line 1291, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.widget.latest-posts .entry-meta > span {
  color: #7a7a7a;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  text-transform: none;
  margin-top: 5px;
  display: block; }

/* line 1300, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.widget.latest-posts ul li a:hover {
  color: #111; }

/* Popular Tags
-------------------------------------------------------*/
/* line 1309, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.tags a {
  background-color: transparent;
  border: 3px solid #e5e5e5;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding: 9px 16px;
  line-height: 1;
  margin: 0 8px 8px 0;
  font-size: 10px;
  color: #ababab;
  display: inline-block;
  float: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  /* line 1323, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .tags a:hover {
    border-color: #111;
    color: #000; }

/*-------------------------------------------------------*/
/* Blog Single Post
/*-------------------------------------------------------*/
/* line 1336, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .entry-item {
  margin-bottom: 0; }

/* line 1340, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .entry-title {
  margin-top: 70px;
  text-align: center; }

/* line 1345, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .entry-meta {
  text-align: center; }

/* line 1349, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .blog-content .entry-title h2 {
  font-size: 28px; }

/* line 1353, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .blog-content .entry-content {
  margin-top: 50px; }

/* line 1357, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .blog-content blockquote {
  margin: 60px 0; }

/* line 1361, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blog-single .blog-content .entry-content > p {
  margin-bottom: 24px; }

/* line 1366, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-content .entry-tags h6,
.entry-share h6 {
  display: inline-block;
  margin-right: 2px;
  font-size: 14px; }

/* line 1375, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-content .entry-tags h6 {
  margin-right: 7px; }

/* line 1379, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-content .entry-tags a {
  font-style: italic;
  font-size: 14px;
  line-height: 37px;
  font-family: "Montserrat", sans-serif;
  color: #7a7a7a; }
  /* line 1386, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .entry-content .entry-tags a:hover {
    color: #111; }

/* line 1391, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-content .entry-share {
  float: right; }

/* line 1398, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-share .socials i,
.entry-share .socials a {
  width: 32px;
  height: 32px;
  line-height: 32px; }

/* line 1405, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-share .socials {
  margin-top: 0;
  display: inline-block; }

/* line 1411, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-tags h6,
.entry-share h6 {
  font-size: 15px;
  text-transform: none;
  letter-spacing: normal; }

/* line 1420, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-comments .comment-avatar {
  position: absolute;
  display: block; }

/* line 1425, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-comments .comment-content {
  padding-left: 132px; }

/* line 1429, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-comments .comment-author {
  display: block;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #111; }

/* line 1438, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-comments h6 {
  font-size: 14px; }

/* line 1442, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.entry-comments .comment-content span a {
  font-size: 13px;
  color: #7a7a7a; }

/* line 1448, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.comment-content p {
  margin-top: 15px; }

/* line 1452, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.comment-content > a {
  font-family: "Montserrat", sans-serif;
  color: #111;
  font-size: 14px; }

/* line 1458, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.comment-reply {
  padding-left: 48px; }

/* line 1462, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.comment-body {
  padding: 35px 0;
  border-bottom: 1px solid #dbdbdb; }

/* line 1467, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.comment-list > li:last-child .comment-body {
  border-bottom: none;
  padding-bottom: 0; }

/*-------------------------------------------------------*/
/* Home Owl Slider
/*-------------------------------------------------------*/
/* line 1477, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.hero-wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

/* line 1486, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.hero-holder {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }

/* line 1493, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.hero-message {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  text-align: center; }
  /* line 1500, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .hero-message h1 {
    color: #fff;
    font-size: 56px;
    font-weight: 700;
    line-height: 1; }

/* line 1508, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.hero-subtitle {
  text-transform: none;
  font-weight: normal;
  color: #fff;
  line-height: 1.5;
  font-size: 20px;
  max-width: 800px;
  margin: 24px auto 0; }

/* line 1518, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.buttons-holder > a {
  margin: 5px; }

/*-------------------------------------------------------*/
/* Home Text Rotator
/*-------------------------------------------------------*/
/* line 1527, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.hero-message.text-rotator h1 {
  font-size: 70px; }

/*-------------------------------------------------------*/
/* Home Video Bg
/*-------------------------------------------------------*/
/* line 1536, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.video-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0; }

/* line 1545, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.video-overlay {
  background-color: rgba(40, 40, 40, 0.5);
  height: 100%;
  width: 100%; }

/* line 1551, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.video-img {
  background: url(../video/video.jpg) center center no-repeat;
  background-size: cover;
  z-index: -101;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

/*-------------------------------------------------------*/
/* Home Angles
/*-------------------------------------------------------*/
/* line 1567, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.main-wrapper-onepage.angles .result-box {
  padding: 30% 0; }

/* line 1571, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.section-wrap.angle-top,
.section-wrap.angle-bottom {
  overflow: visible; }

/* line 1576, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.bg-light.angle-top:before,
.bg-light.angle-bottom:after {
  background-color: #f7f7f7; }

/* line 1581, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.bg-dark.angle-top:before,
.bg-dark.angle-bottom:after {
  background-color: #242424; }

/* line 1586, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.angle-top:before {
  background: none repeat scroll 0 0 #fff;
  content: "";
  margin-top: -199px;
  min-height: 150px;
  position: absolute;
  -webkit-transform: skewY(-2deg);
  -moz-transform: skewY(-2deg);
  -ms-transform: skewY(-2deg);
  -o-transform: skewY(-2deg);
  transform: skewY(-2deg);
  width: 100%;
  z-index: 1; }

/* line 1597, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.angle-bottom:after {
  background: none repeat scroll 0 0 #fff;
  content: "";
  margin-top: 40px;
  min-height: 150px;
  position: absolute;
  -webkit-transform: skewY(-2deg);
  -moz-transform: skewY(-2deg);
  -ms-transform: skewY(-2deg);
  -o-transform: skewY(-2deg);
  transform: skewY(-2deg);
  width: 100%;
  z-index: 1; }

/* line 1608, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#portfolio.angle-bottom:after {
  margin-top: 140px; }

/* line 1612, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.main-wrapper-onepage.angles .process,
.main-wrapper-onepage.angles .parallax-testimonials {
  padding: 200px 0; }

/* line 1617, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.main-wrapper-onepage.angles .footer.minimal {
  text-align: left; }

/* line 1621, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.main-wrapper-onepage.angles .gmap {
  height: 600px; }

/* Services
-------------------------------------------------------*/
/* line 1631, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.services.style-2 a {
  position: absolute;
  font-size: 36px;
  line-height: 1;
  color: #c1c1c1; }

/* line 1638, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.services.style-2 .service-item-box {
  padding: 0 0 0 60px;
  margin-bottom: 50px; }

/* line 1643, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.services.style-2 .service-item-box h3 {
  margin-bottom: 10px; }

/* Latest Works
-------------------------------------------------------*/
/* line 1653, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.portfolio-filter {
  list-style: none;
  margin-bottom: 40px;
  cursor: default;
  font-size: 13px;
  text-align: center; }
  /* line 1660, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-filter a.active,
  .portfolio-filter a:hover {
    color: #111;
    border-color: #111; }
  /* line 1666, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-filter a {
    display: inline-block;
    margin: 0 5px 5px 0;
    color: #ababab;
    text-decoration: none;
    padding: 7px 15px;
    border: 3px solid #e5e5e5;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

/* line 1680, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-container {
  margin-bottom: 40px; }

/* line 1684, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item .work-overlay:hover,
.work-item:hover .portfolio-overlay {
  opacity: 1;
  z-index: 1; }

/* line 1690, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.portfolio-overlay {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 1704, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.project-icons {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  margin-top: -20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  /* line 1712, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .project-icons a {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin: 0 3px;
    line-height: 40px;
    text-align: center;
    color: #111;
    background-color: #fff;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    /* line 1725, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
    .project-icons a:hover {
      color: #fff;
      background-color: #2b8040; }

/* line 1732, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.work-item:hover .portfolio-overlay .project-icons {
  position: absolute;
  top: 50%; }

/* line 1737, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.works-grid.titles .work-description h3,
.works-grid.titles .work-description span,
#owl-related-works .work-description h3,
#owl-related-works .work-description span {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none; }

/* line 1744, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.works-grid.titles .work-description,
#owl-related-works .work-description {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
  text-align: center; }

/* line 1754, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.works-grid.titles .work-description a,
#owl-related-works .work-description a {
  color: #111; }
  /* line 1758, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .works-grid.titles .work-description a:hover,
  #owl-related-works .work-description a:hover {
    color: #2b8040; }

/* Call to Action Style-2
-------------------------------------------------------*/
/* line 1767, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.call-to-action.style-2 {
  padding: 200px 0; }
  /* line 1770, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .call-to-action.style-2 h2 {
    font-size: 32px;
    color: #fff;
    margin: 0 0 25px; }
  /* line 1776, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .call-to-action.style-2 a {
    margin: 10px 4px 0; }

/* Ipad with Features
-------------------------------------------------------*/
/* line 1785, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.promo-device img {
  display: inline-block; }

/* line 1789, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.features-icons a {
  position: absolute; }

/* line 1793, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.features-text {
  padding-left: 100px; }

/* line 1797, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.features-icons .service-item-box {
  padding: 0;
  margin-bottom: 50px;
  background-color: transparent; }

/* Pricing Tables
-------------------------------------------------------*/
/* line 1806, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-3-col {
  background-color: #f7f7f7;
  text-align: center; }

/* line 1811, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-title {
  background-color: #303030;
  padding: 45px 30px; }
  /* line 1815, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pricing-title h3 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px; }

/* line 1822, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.best-price {
  background-color: #2b8040; }

/* line 1826, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-price {
  font-size: 25px;
  color: #111;
  background-color: #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -55px;
  line-height: 1.3;
  padding: 28px 25px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(14, 16, 9, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(14, 16, 9, 0.1);
  -ms-box-shadow: 0px 0px 5px 0px rgba(14, 16, 9, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(14, 16, 9, 0.1); }

/* line 1842, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-currency {
  font-family: "Montserrat", sans-serif; }

/* line 1846, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-term {
  font-size: 12px;
  display: block; }

/* line 1851, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-features {
  padding: 100px 40px 40px;
  color: #7a7a7a; }
  /* line 1855, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .pricing-features li {
    padding: 10px 0; }

/* line 1860, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.pricing-button {
  padding: 0 40px 50px 40px; }

/* Fun Facts style-2
-------------------------------------------------------*/
/* line 1870, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-boxes.style-2 .result-box {
  padding: 20px 0;
  text-align: center; }

/* line 1875, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-boxes.style-2 .statistic span.counter-text {
  color: #fff;
  font-size: 13px; }

/* line 1880, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-boxes.style-2 .statistic span {
  float: none;
  margin-left: 0;
  line-height: 1.2;
  font-size: 50px; }

/* line 1887, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.result-boxes.style-2 .result-wrap {
  width: auto; }

/* Testimonials style-2
-------------------------------------------------------*/
/* line 1898, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .testimonial-text {
  color: #7a7a7a; }

/* line 1902, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .owl-carousel {
  position: static; }

/* line 1906, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .owl-pagination {
  position: static;
  margin-top: 30px; }

/* line 1911, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .owl-page span {
  border-color: #a7a7a7; }

/* line 1915, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .owl-page.active span {
  background-color: #7a7a7a;
  border-color: #7a7a7a; }

/* line 1920, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .testimonial span {
  color: #ababab;
  display: inline-block;
  font-weight: bold;
  font-size: 14px; }

/* line 1927, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.testimonials.style-2 .testimonial-text {
  margin: 10px 0 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px; }

/* Page Title Style-2
-------------------------------------------------------*/
/* line 1939, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.page-title.style-2 {
  background-color: #f7f7f7;
  padding: 60px 0; }
  /* line 1943, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title.style-2 .container {
    height: auto; }
  /* line 1947, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title.style-2 .title-text h1 {
    margin: 0;
    font-size: 28px; }
  /* line 1952, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title.style-2 .breadcrumb {
    position: absolute;
    right: 0;
    width: auto;
    background-color: transparent;
    top: 50%;
    padding: 0; }
  /* line 1961, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title.style-2 .breadcrumb a,
  .page-title.style-2 .breadcrumb > li + li:before {
    color: #7a7a7a; }
  /* line 1966, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .page-title.style-2 .breadcrumb > .active,
  .page-title.style-2 .breadcrumb a:hover {
    color: #111; }

/*-------------------------------------------------------*/
/* Portfolio
/*-------------------------------------------------------*/
/* line 1977, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.works-grid.titles h3,
#owl-related-works h3 {
  font-size: 14px; }

/* line 1982, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.call-to-action.bg-light {
  background-color: #f7f7f7; }

/* line 1986, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.portfolio-description {
  padding-left: 10%; }
  /* line 1989, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-description p,
  .portfolio-description ul,
  .portfolio-description a.btn {
    margin-bottom: 30px; }
  /* line 1995, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-description h2 {
    font-size: 22px;
    margin-bottom: 15px; }
  /* line 2000, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-description ul > li {
    line-height: 30px;
    color: #111; }
  /* line 2005, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-description ul > li a {
    color: #7a7a7a; }
  /* line 2009, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .portfolio-description ul > li a:hover {
    color: #2b8040; }

/* line 2016, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.related-projects .work-container {
  margin-bottom: 0; }

/* line 2020, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.related-projects .heading-inline,
.related-projects .customNavigation {
  display: inline-block; }

/* line 2025, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.related-projects .customNavigation a {
  background-color: #f2f2f2;
  color: #111;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 0;
  line-height: 30px;
  border: none; }

/* line 2036, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.related-projects .customNavigation i {
  font-size: 14px;
  line-height: 30px; }

/* line 2041, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.related-projects .customNavigation a:hover {
  background-color: #2b8040;
  color: #fff; }

/* line 2047, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#owl-related-works .owl-item .work-item {
  margin: auto 15px; }

/*-------------------------------------------------------*/
/* 404
/*-------------------------------------------------------*/
/* line 2058, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.page-404 h1 {
  font-size: 200px; }

/* line 2062, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.page-404 a,
.page-404 p {
  font-size: 14px; }

/* Intro
-------------------------------------------------------*/
/* line 2072, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.section-wrap.intro {
  padding: 170px 0 150px; }

/* line 2076, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.intro.style-2 .intro-text p {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
  color: #111; }

/* Portfolio Masonry
-------------------------------------------------------*/
/* line 2088, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.masonry-grid {
  width: 100%; }
  /* line 2091, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-item {
    width: 50%;
    float: left; }
  /* line 2096, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-item.quarter {
    width: 25%; }
  /* line 2100, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-description {
    width: 100%;
    text-align: center;
    margin-top: -24px;
    top: 50%;
    left: 0;
    padding: 0 15px; }
  /* line 2109, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-img img {
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s linear;
    -ms-transition: all 1.5s linear;
    -o-transition: all 1.5s linear;
    transition: all 1.5s linear; }
  /* line 2113, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-description h3,
  .masonry-grid .work-description span {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0); }
  /* line 2118, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-description h3 {
    font-size: 20px; }
  /* line 2122, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  .masonry-grid .work-overlay {
    border: none; }

/*-------------------------------------------------------*/
/* Portfolio About Me
/*-------------------------------------------------------*/
/* line 2132, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.about-me .my-photo {
  width: 100%; }

/* line 2136, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.about-me .info {
  padding-left: 7%; }

/*-------------------------------------------------------*/
/* Youtube Video bg
/*-------------------------------------------------------*/
/* line 2145, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
#ytb-wrap {
  background-size: cover; }
  /* line 2148, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
  #ytb-wrap .hero-text {
    color: #fff;
    text-shadow: none;
    font-weight: 700;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.02em;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    font-size: 100px;
    text-transform: none; }

/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/
/* line 2168, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.section-headings p {
  margin-bottom: 30px; }

/* line 2172, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
blockquote {
  padding: 0 0 20px;
  margin: 0;
  border: none; }

/* line 2178, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
blockquote > p {
  font-size: 18px;
  line-height: 30px;
  font-style: italic;
  margin-bottom: 5px !important;
  position: relative; }

/* line 2186, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
blockquote > span {
  font-size: 14px; }

/* line 2190, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blockquote-style-1 {
  padding: 8% 10%;
  border: 4px solid #e5e5e5; }

/* line 2195, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.blockquote-style-2 {
  padding: 20px 40px;
  border-left: 3px solid #333333; }

/* line 2200, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.dropcap.style-1 {
  float: left;
  color: #333;
  font-size: 52px;
  line-height: 46px;
  padding-top: 4px;
  padding-right: 10px; }

/* line 2209, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.dropcap.style-2 {
  float: left;
  color: #fff;
  text-align: center;
  background-color: #333333;
  width: 38px;
  height: 38px;
  font-size: 24px;
  line-height: 38px;
  margin: 10px 10px 0 0; }

/* line 2221, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.highlight {
  padding: 3px 5px;
  color: #fff;
  background-color: #cc1236; }

/* line 2227, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.bullets li,
.arrows li,
.arrows-2 li,
.numbers li {
  margin-bottom: 10px; }

/* line 2234, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.bullets {
  list-style-type: disc;
  margin-left: 17px; }

/* line 2239, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.bullets li:before {
  font-size: 18px;
  color: #333333;
  padding-right: 0.5em;
  line-height: 1;
  vertical-align: middle; }

/* line 2247, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.arrows i {
  margin-right: 5px; }

/* line 2251, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.arrows i,
.arrows-2 i {
  color: #333333; }

/* line 2256, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.arrows-2 i {
  font-size: 12px;
  margin-right: 7px; }

/* line 2261, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
ol.numbers {
  padding-left: 20px; }

/* line 2265, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_layout.scss */
.section-columns p {
  margin-bottom: 40px; }

/*-------------------------------------------------------*/
/* Shortcodes
/*-------------------------------------------------------*/
/* Accordions
-------------------------------------------------------*/
/* line 11, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .panel-content {
  padding: 10px 0 10px 20px; }

/* line 15, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: 12px;
  padding: 13px 20px;
  background-color: #fff;
  color: #7a7a7a;
  margin-bottom: 10px;
  border: 3px solid #d1d1d1;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 32, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a.active {
  color: #111;
  background-color: #fff;
  border-color: #333; }

/* line 38, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a:after {
  font-family: "ElegantIcons";
  position: absolute;
  right: 10px;
  top: 2px;
  color: #7a7a7a;
  font-weight: normal;
  font-size: 26px; }

/* line 48, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a:after {
  content: "\35"; }

/* line 52, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a.active:after {
  content: "\33";
  color: #333; }

/* line 57, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a:hover {
  color: #fff;
  background-color: #333;
  border-color: #333; }

/* line 63, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.accordion > .acc-panel > a:hover:after {
  color: #fff; }

/* Tabs
-------------------------------------------------------*/
/* line 73, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.nav.nav-tabs {
  border-bottom: none; }
  /* line 76, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .nav.nav-tabs > li {
    margin-bottom: 0; }
  /* line 80, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .nav.nav-tabs > li.active > a {
    border: 3px solid #333;
    background-color: #fff;
    padding: 13px 20px;
    color: #333; }
  /* line 87, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .nav.nav-tabs > li > a {
    padding: 13px 20px;
    background-color: #fff;
    border: 3px solid #d1d1d1;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin-right: 10px;
    font-family: "Montserrat", sans-serif;
    color: #7a7a7a;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  /* line 101, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .nav.nav-tabs > li > a:hover {
    color: #fff;
    background-color: #333;
    border-color: #111; }

/* line 108, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.tab-content {
  padding: 20px 0; }
  /* line 111, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .tab-content > .tab-pane > p {
    margin-bottom: 20px; }

/* Buttons
-------------------------------------------------------*/
/* line 120, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.section-buttons .btn {
  margin-bottom: 20px; }

/* line 122, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.02em;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 3px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #111;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
  /* line 137, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-color {
    background-color: #2b8040; }
  /* line 141, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-transparent {
    background-color: transparent;
    border: 3px solid #fff; }
  /* line 146, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-stroke {
    color: #111;
    border: 3px solid #111;
    background-color: transparent; }
  /* line 152, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-dark {
    background-color: #333; }
  /* line 156, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-white {
    background-color: #fff;
    color: #111; }
  /* line 161, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-light {
    background-color: #f5f5f5;
    color: #111; }
  /* line 166, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn:hover {
    -webkit-transition: all 0.2 ease-in-out;
    -moz-transition: all 0.2 ease-in-out;
    -ms-transition: all 0.2 ease-in-out;
    -o-transition: all 0.2 ease-in-out;
    transition: all 0.2 ease-in-out;
    color: #fff;
    background-color: #2b8040;
    border-color: transparent; }
  /* line 173, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn:focus {
    outline: none;
    color: #fff; }
  /* line 178, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-white:focus {
    color: #111; }
  /* line 182, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .btn.btn-color:hover {
    background-color: #111; }

/* line 187, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.btn-lg {
  font-size: 14px;
  padding: 13px 34px; }

/* line 192, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.btn-md {
  font-size: 11px;
  padding: 10px 25px; }

/* line 197, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.btn-sm {
  font-size: 10px;
  padding: 8px 22px; }

/* Pie Charts
-------------------------------------------------------*/
/* line 206, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.pie-chart {
  margin-bottom: 40px; }

/* line 210, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.chart {
  position: relative;
  display: inline-block;
  width: 174px;
  height: 174px;
  text-align: center; }
  /* line 217, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .chart canvas {
    position: absolute;
    top: 0;
    left: 0; }

/* line 224, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.percent {
  display: inline-block;
  line-height: 174px;
  z-index: 2;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  color: #111; }
  /* line 232, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .9em; }

/* Alert Boxes
-------------------------------------------------------*/
/* line 243, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.alert {
  padding: 14px 20px;
  margin-bottom: 20px;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #fff; }

/* line 251, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.alert-dismissible .close {
  right: 0;
  top: 0;
  color: inherit;
  position: relative; }

/* line 258, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.close {
  opacity: 1;
  text-shadow: none; }

/* line 263, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.alert-success {
  background-color: #aec2a1; }

/* line 264, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.alert-info {
  background-color: #a0b7cb; }

/* line 265, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.alert-warning {
  background-color: #ccbfa9; }

/* line 266, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.alert-danger {
  background-color: #cba0a0; }

/*-------------------------------------------------------*/
/* Form Elements
/*-------------------------------------------------------*/
/* line 273, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="search"],
select,
textarea {
  position: relative;
  height: 47px;
  line-height: 47px;
  border: 2px solid #e3e3e3;
  background-color: transparent;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 16px;
  color: #7a7a7a;
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  -ms-transition: border-color 0.3s ease-in-out;
  -o-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out; }

/* line 295, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
textarea#comment {
  height: 190px;
  padding: 0 16px;
  margin-bottom: 25px; }

/* line 301, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
  border-color: #2b8040;
  outline: none;
  box-shadow: none; }

/* line 320, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
textarea {
  height: auto;
  padding: 0px 16px; }

/* line 325, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #7a7a7a; }

/* line 330, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #7a7a7a;
  opacity: 1; }

/* line 336, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #7a7a7a;
  opacity: 1; }

/* line 342, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #7a7a7a; }

/* line 347, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
select {
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer; }

/* line 355, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
select::-ms-expand {
  display: none; }

/* line 359, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.select {
  position: relative;
  cursor: pointer; }
  /* line 363, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
  .select i {
    position: absolute;
    top: 14px;
    right: 18px;
    pointer-events: none;
    font-size: 18px; }

/* Checkboxes & Radio Buttons
-------------------------------------------------------*/
/* line 376, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="checkbox"] {
  display: none; }

/* line 380, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="checkbox"] + label:before {
  width: 22px;
  height: 22px;
  background-color: #e3e3e3;
  content: "";
  display: inline-block;
  font-size: 13px;
  margin: -4px 12px 0 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer; }

/* line 393, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #111; }

/* line 399, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  letter-spacing: normal;
  color: #7a7a7a;
  font-size: 15px; }

/* line 410, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
.radio-buttons > li,
.checkboxes > li {
  padding: 7px 0; }

/* line 415, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="radio"] {
  display: none; }

/* line 419, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="radio"] + label:before {
  display: inline-block;
  content: "";
  width: 22px;
  height: 22px;
  background-color: #e3e3e3;
  border-radius: 40px;
  margin: -3px 10px 0 0;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0; }

/* line 432, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="radio"]:checked + label:before {
  width: 22px;
  height: 22px;
  background-color: #111;
  border: 6px solid #e3e3e3; }

/* line 439, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
input[type="radio"]:focus {
  outline: none; }

/* line 443, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_shortcodes.scss */
label {
  font-weight: normal;
  color: #111;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  vertical-align: middle; }

/*-------------------------------------------------------*/
/* Footer
/*-------------------------------------------------------*/
/* line 5, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer {
  font-family: "Montserrat", sans-serif; }
  /* line 8, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer.minimal {
    padding: 120px 0;
    text-align: center; }
  /* line 13, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer.minimal .copyright {
    display: block;
    margin-top: 70px;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em; }
  /* line 22, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer.minimal .copyright a {
    color: #909090; }

/* line 30, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.socials.footer-socials a {
  margin: 0 2px; }
  /* line 33, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .socials.footer-socials a:hover i {
    color: #fff;
    background-color: #2b8040; }

/* line 39, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.socials.footer-socials i {
  width: 37px;
  height: 37px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #1b1b1b;
  color: #dbdbdb;
  line-height: 37px;
  text-align: center;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/*-------------------------------------------------------*/
/* Footer Type-2
/*-------------------------------------------------------*/
/* line 58, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer.footer-type-2 {
  background-color: #111; }

/* line 62, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-widgets {
  padding: 100px 0; }
  /* line 65, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-widgets h5 {
    margin-bottom: 24px;
    font-size: 16px;
    color: #fff; }
  /* line 71, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-widgets a {
    color: #7a7a7a; }
    /* line 74, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
    .footer-widgets a:hover {
      color: #fff; }

/* line 83, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-links ul > li {
  padding: 13px 0;
  border-bottom: 1px solid #292929;
  line-height: 24px; }

/* line 89, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-links li {
  padding-bottom: 7px; }
  /* line 92, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-links li:last-child {
    padding-bottom: 0; }

/* line 99, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-get-in-touch p {
  margin-bottom: 7px; }

/* line 103, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
p.footer-address {
  margin-bottom: 24px; }

/* line 107, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-entry {
  padding: 9px 0 14px;
  border-bottom: 1px solid #292929; }
  /* line 111, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-entry p {
    margin-bottom: 1px; }
  /* line 115, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-entry span {
    color: #4c4c4c;
    font-size: 12px; }

/* line 121, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-entry:first-child,
.footer-links li:first-child {
  padding-top: 0; }

/* line 126, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-entry:last-child,
.footer-links li:last-child {
  border-bottom: none;
  padding-bottom: 0; }

/* line 132, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-socials .social-icons a {
  margin-right: 3px;
  float: left;
  display: inline-block;
  width: 32px;
  height: 32px;
  overflow: hidden;
  background-color: transparent;
  color: #7a7a7a;
  line-height: 32px;
  text-align: center;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 149, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.social-icons.light a {
  background-color: #f7f7f7; }

/* line 153, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.social-icons i {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  position: relative; }

/* line 162, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.social-icons a:hover {
  color: #fff; }

/* line 169, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.bottom-footer {
  background-color: #0c0c0c;
  padding: 34px 0; }

/* line 174, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.copyright span {
  font-size: 13px;
  line-height: 32px; }
  /* line 178, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .copyright span a {
    color: #7a7a7a; }
    /* line 181, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
    .copyright span a:hover {
      color: #fff; }

/*-------------------------------------------------------*/
/* Footer Type-3
/*-------------------------------------------------------*/
/* line 192, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
.footer-type-3 {
  padding: 100px 0;
  font-size: 13px;
  line-height: 37px; }
  /* line 197, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-type-3 .footer-socials {
    text-align: right; }
  /* line 201, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-type-3 .footer-socials i {
    background-color: transparent;
    color: #7a7a7a; }
  /* line 206, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-type-3 .footer-socials a:hover i {
    background-color: transparent;
    color: #111; }
  /* line 211, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
  .footer-type-3 a {
    color: #7a7a7a; }
    /* line 214, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/_footer.scss */
    .footer-type-3 a:hover {
      color: #111; }

/*# sourceMappingURL=../../sass */